import { BottleSizes, unitsPerCase } from 'src/app/core/constants/bottle-sizes.constants';
import { cryptoSecret } from './../constants/crypto-secret.constants';
import { EventEmitter, Injectable, Output, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil, tap } from 'rxjs/operators';
import { unitsPerCases } from '../constants/units-per-case.constatnts';
import { DatePipe } from '@angular/common';
import { liquorTypes } from '../constants/liquor-type.constants';

declare const CryptoJS: any;
@Injectable({
    providedIn: 'root'
})

export class UtilService {
    @Output() refreshPage = new EventEmitter<boolean>();
    public _onDestroy = new Subject<void>();

    constructor(
        private dialog: MatDialog,
        private messageService: MessageService,
        private datePipe: DatePipe
    ) { }

    /**
     * filter functionality in table
     * @param dataSource
     * @param search
     */
    applyFilter(dataSource, search) {
        const filterValue = search;
        dataSource.filter = filterValue.trim().toLowerCase();

        if (dataSource.paginator) {
            dataSource.paginator.firstPage();
        }
    }
    /**
     * pagination for tables in application
     * @param dataSource
     * @param paginator
     * @param sort
     * @param nativeElement
     * @param callBack
     */
    ngAfterViewInit(dataSource, paginator, sort, nativeElement, callBack) {
        if (dataSource) {
            dataSource.paginator = paginator;
            dataSource.sort = sort;
        }
        if (callBack) {
            // server-side search
            fromEvent(nativeElement, 'keyup')
                .pipe(
                    filter(Boolean),
                    debounceTime(150),
                    distinctUntilChanged(),
                    tap((text) => {
                        callBack();
                    })
                )
                .subscribe();
        }

    }

    // /* Password Encryption */
      /**
     * Password Encryption
     * @param p
     */
    // encryptPassword(p) {
    //     var et:any = Math.floor((new Date().getTime())/1000000);
    //     et = et%1000000;
        
    //     et = JSON.stringify(et)
    //     let pc = '';
        
    //     p.split('').forEach((res,i) => {
        
        
    //     if(i+1 <= et.length ) {
    //     pc += res.charCodeAt()+ Number(et.charAt(i))
    //    // console.log(res.charCodeAt(), et.charAt(i) )
    //     }
        
    //     else if(i+1 > et.length ){
    //     pc += res.charCodeAt()+ Number(et.charAt(i-et.length))
    //     //console.log(res.charCodeAt(), et.charAt(i-et.length))
    //     }
        
    //     })
      
    //     return pc;
    //   }

    encryptPassword(password): any {
        let enc = CryptoJS.AES.encrypt(password, cryptoSecret.key);
        enc = enc.toString();
        return enc;
    }

    decrypt(decrypt_key): any {
        const decrypt = CryptoJS.AES.decrypt(decrypt_key, cryptoSecret.key).toString(CryptoJS.enc.Utf8);
        return decrypt;
    }

    ///* End of Encryption */
    /**
     * openModalDialog method in application
     * @param component
     * @param data
     * @param width
     * @param height
     * @param autoFocus
     * @param refreshPage
     * @param callBack
     */
    openModalDialog(component, data, width, height, autoFocus, refreshPage, callBack, actionLink?) {

        // console.log(component, data,"106::::")
        const modalData = data ? {...data, actionLink: actionLink}: null;
        const dialogRef = this.dialog.open(component, {
            width,
            height,
            autoFocus,
            data: modalData,
            disableClose: true,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result !== true && result !== undefined) {
                if (result) {
                    this.messageService.add({ severity: 'success', summary: 'Success', detail: result });
                }
                if (refreshPage?.emit) {
                    refreshPage.emit();
                }else{
                    refreshPage();
                }
                if (callBack) {
                    callBack();
                }
            }
            if(data && data.colName && data.colName === "packingList") {
                // refreshPage.emit(); // reloads on closing of packing list in distillery shipments
                if (refreshPage?.emit) {
                    refreshPage.emit();
                }else{
                    refreshPage();
                }
            }
        });
    }
    /**
     * restrict number type field length
     * @param event
     * @param length
     */
    restrictNumberInput(event, length) {
        // console.log(event, "input::::::")
        if (event && length) {
            const value = event.target.value;

            if (value.length === length || value < 0 || (event.key !== '.' && isNaN(Number.parseInt(event.key)))) {
                event.preventDefault();
            }
        }
    }
    copyMessage(val: string){
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }
    getFinancialYearMonth(year): any {
        const fyStart = parseInt(year.split('-')[0], 10);
        const fyLast = parseInt(year.split('-')[1], 10);
        const datas: any = [];
        const monthLists: any = this.getAllMonth();
        for (let i = 4; i < 13; i++) {
            const fyLabel = i >= 4 ? fyStart : fyLast;
            const month = {key: monthLists[i - 1], label: `${monthLists[i - 1]}-${fyLabel}`, type: 'decimal', valueType: 'decimal'}
            datas.push(month);
            if (i === 12) {
                i = 0;
            }
            if (new Date().getFullYear() === fyStart) {
                if (i === (new Date().getMonth() + 1)) {
                    return datas;
                }
            } else {
                if (i === 3) {
                    return datas;
                }
            } 
        }
    }
    getAllMonth(): any {
        return [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];
    }
    getFromToDate(): any {
        return {fromDate: this.getDateOnly(new Date()), toDate: this.getDateOnly(new Date())}
    }
    getEmptyFromToDate(): any {
        return {fromDate: '', toDate: ''}
    }


    decimalFilter(event: any) {
        const reg = /^-?\d*(\.\d{0,3})?$/;
        let input = event.target.value + String.fromCharCode(event.charCode);
      
        if (!reg.test(input)) {
            event.preventDefault();
        }
      }
      
      decimalFilterForTwo(event: any) {
          const reg = /^-?\d*(\.\d{0,2})?$/;
          let input = event.target.value + String.fromCharCode(event.charCode);
        
          if (!reg.test(input)) {
              event.preventDefault();
          }
        }

        phoneNumberValidation() {
            return /^[0-9-+ ]{0,14}$/;
            // return /^((\+{1}91){1})? ?-?[5-9]{1}[0-9]{9}$/;
        }

    // restrictNumberMinLengthInput(event, length) {
    //     console.log(event, "input::::::")
    //     if (event && length) {
    //         const value = event.target.value;

    //         if (value.length === length || value < 0 || (event.key !== '.' && isNaN(Number.parseInt(event.key)))) {
    //             event.preventDefault();
    //         }
    //     }
    // }

    restrictNumberFloatInput(event, length) {
        console.log(event, "input::::::")
        if (event && length) {
            const value = event.target.value;
            if (value.length === length || value < 0 || isNaN(Number.parseInt(event.key)) || event.key === '.') {
                event.preventDefault();
            }
        }
    }
    /**
     * regex for email validation
     * @returns
     */
    validEmailRegex() {
        // return "[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,3}";
        return "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z]+$";
    }
    /**
     * allow only alphabets and space
     * @returns
     */
    allowOnlyAlphabets() {
        return "^[A-Za-z ]+$"
        // "^[a-zA-Z0-9 ._-]$"
    }

    validStaffName() {
        return "^[a-zA-Z0-9 ._-]+$"
        // "^[a-zA-Z0-9 ._-]$"
    }
    /**
     * regex for valid user name in application
     * @returns
     */
    validUserName() {
        return "^[a-zA-Z0-9@._-]+$"
    }
    /**
     * regex for valid user name in application
     * @returns
     */
    allowOnlyNumbers() {
        return "/^[0-9]+(\.[0-9]{1,2})?$/"
    }
    /**
     * filter method for ngx-mat-select-search component
     * @param $scope
     * @param FormControl
     * @param filteredArray
     * @param serverResp
     */
    matSelectFilter($scope, FormControl, filteredArray, serverResp) {
        $scope[FormControl].valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                $scope[filteredArray].next($scope[serverResp].filter((value,i) => {
                    
                    return JSON.stringify(value).toLowerCase().includes($scope[FormControl].value.toLowerCase())
                }));
            });
    }
    /**
     * get component by passing name and ComponentFactoryResolver
     * @param component
     * @param resolver
     * @returns
     */
    getComponentByName(component, resolver) {
        const currentModule = resolver['ngModule']["_r3Injector"].source;
        const factories = Array.from(resolver['ngModule']["_r3Injector"].records.keys());
        let comp = <Type<any>>factories.find((x: any) => x.name === currentModule)["ɵmod"].declarations.find((y: any) => y.name === component);
        if(!comp) {
            comp = <Type<any>>factories.find((x: any) => x.name === currentModule)["ɵmod"]['imports'].find((x: any) => x.name === "CoreModule")["ɵmod"].declarations.find((y: any) => y.name === component)
        }
        return comp
    }

    /**
     * To validate given form
     * @param changes
     * @param requiredFields
     * @returns
     */
    isFormValid(changes, requiredFields) {
        return !!Object.keys(changes).filter(item => requiredFields.indexOf(item) !== -1 && !changes[item]).length;
    }

     /**
     * To validate given form
     * @param productCode
     * @returns
     */
      productCodeToDetails(productCode) {
        if (productCode.length === 11) {
            return {supplierCode: productCode.substr(0,4), liquorType: productCode.substr(4,1), brandCode: productCode.substr(5,3), packType: productCode.substr(8,1), size: productCode.substr(9,2), unitsPerCase: unitsPerCases[productCode.substr(9,2)]};
        } else {
            return {supplierCode: productCode.substr(0,4), liquorType: productCode.substr(4,1), brandCode: productCode.substr(5,4), packType: productCode.substr(9,1), size: productCode.substr(10,2), unitsPerCase: unitsPerCases[productCode.substr(10,2)]};
        }
    }

    dateFormat() {
        return 'EEE, MMM d, y';
    }

    dateTimeFormat() {
        return 'EEE, MMM d, y, h:mm:ss a'
    }


      
    formatCurrency(value) {
        const formattedPrice = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "INR",
        }).format(value);
        if (formattedPrice != "₹NaN") {
            return formattedPrice
        }
    }
    getCurrentDateTime(): any {
        const now = new Date();
        const todayDate: any = new Date();
        todayDate.setDate(now.getDate());
        return todayDate;
    }
    amountOnly(evt, val: any): any {
        if (!val) {
            return
        }
        val = val.toString();
        var charCode = (evt.which) ? evt.which : evt.keyCode
            var dotcontains = val.indexOf(".") != -1;
            if (dotcontains)
                if (charCode == 46) return false;
            if (charCode == 46) return true;
            if (charCode > 31 && (charCode < 48 || charCode > 57))
                return false;
            return true;
    }
    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
        }
        return true;
    }
    // Only numbers (No symbols including hyphens)
    onlyDigits(event){
        const charCode = (event.which) ? event.which : event.keyCode; 
        if(charCode >= 48 && charCode <= 57){
            return true
        }
        return false
    }

    onlyAlphabets(event){
        const charCode = event.keyCode; 
        if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode == 8){
            return true;
        }
        else{
            return false;
        }
}

    onlyAlphabetsWithHyphen(event){
        const charCode = event.keyCode; 
        if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode == 8 || charCode == 45 || charCode >= 48 && charCode <= 57){
            return true;
        }
        else{
            return false;
        }
    }
    checkSpoolsQty(controls) {
        if(controls.value == '' || controls.value == null){
            controls.setErrors({'incorrect': true});
            return false;
        }
        else if(controls.value%20000 == 0){
            controls.setErrors(null)
            return true;
        }else{
            controls.setErrors({'incorrect': true});
            return false;
        }
      }
    getArrayofArray(array): any {
        const arr: any = [];
        array.map(res => {
            res.map(item => {
            arr.push(item);
            });
        });
        return arr;
    }
    getMin(array): any {
        const arr = this.getArrayofArray(array).sort((a, b) => a - b );
        return arr[0];
    }
    getMax(array): any {
        const arr = this.getArrayofArray(array).sort((a, b) => a - b );
        return arr[arr.length - 1];
    }
    getAge(date): any {
        date = new Date(date);
        const today = new Date();
        const Difference_In_Time = today.getTime() - date.getTime();
        return Math.ceil(Difference_In_Time / (1000 * 3600 * 24));
    }

      getFinancialYearList(){
        let currentYear = new Date().getFullYear() + 1;
        let earliestYear = currentYear - 2;
        let financialYearList = [];
    
        for(let i=earliestYear;i <= currentYear;i++) {
          financialYearList.push(`${i-1}-${i}`);
        }
        return financialYearList;
      }
    formatString(str): any {
        if (str) {
            return str.replace(/.*?-/, '');
        }
        return str;
    }
    formatStringByParamAndIndex(str, param, index): any {
        if (str) {
            str = str.split(param);
            if (str[index]) {
                str = str[index];
            }
        }
        return str;
    }
    formatCode(str): any {
        if (str) {
            str = str.split('-');
            if (str[0]) {
                str = str[0];
            }
        }
        return str;
    }

    getTotalIndentValue(taxDetails){
        const indentValue = Math.ceil(taxDetails.additionalExciseDuty) + Math.ceil(taxDetails.exciseDuty)+ Math.ceil(taxDetails.totalRateOfSmP) + Math.ceil(taxDetails.vat) +Math.ceil(taxDetails.pdPrice)+Math.ceil(taxDetails.totalAreTax)+Math.ceil(taxDetails.afee_amount)+Math.ceil(taxDetails.rpm);
        return indentValue;
    }

    convertNumberToWords(amount) {
        if (!amount) {
            return;
        }
        var words = new Array();
        words[0] = '';
        words[1] = 'One';
        words[2] = 'Two';
        words[3] = 'Three';
        words[4] = 'Four';
        words[5] = 'Five';
        words[6] = 'Six';
        words[7] = 'Seven';
        words[8] = 'Eight';
        words[9] = 'Nine';
        words[10] = 'Ten';
        words[11] = 'Eleven';
        words[12] = 'Twelve';
        words[13] = 'Thirteen';
        words[14] = 'Fourteen';
        words[15] = 'Fifteen';
        words[16] = 'Sixteen';
        words[17] = 'Seventeen';
        words[18] = 'Eighteen';
        words[19] = 'Nineteen';
        words[20] = 'Twenty';
        words[30] = 'Thirty';
        words[40] = 'Forty';
        words[50] = 'Fifty';
        words[60] = 'Sixty';
        words[70] = 'Seventy';
        words[80] = 'Eighty';
        words[90] = 'Ninety';
        amount = amount.toString();
        var atemp = amount.split(".");
        var number = atemp[0].split(",").join("");
        var n_length = number.length;
        var words_string = "";
        if (n_length <= 9) {
            var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
            var received_n_array = new Array();
            for (var i = 0; i < n_length; i++) {
                received_n_array[i] = number.substr(i, 1);
            }
            for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
                n_array[i] = received_n_array[j];
            }
            for (var i = 0, j = 1; i < 9; i++, j++) {
                if (i == 0 || i == 2 || i == 4 || i == 7) {
                    if (n_array[i] == 1) {
                        n_array[j] = 10 + parseInt(n_array[j] as any);
                        n_array[i] = 0;
                    }
                }
            }
          let  value;
            for (var i = 0; i < 9; i++) {
                if (i == 0 || i == 2 || i == 4 || i == 7) {
                    value = n_array[i] * 10;
                } else {
                    value = n_array[i];
                }
                if (value != 0) {
                    words_string += words[value] + " ";
                }
                if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
                    words_string += "Crores ";
                }
                if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
                    words_string += "Lakhs ";
                }
                if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
                    words_string += "Thousand ";
                }
                if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
                    words_string += "Hundred and ";
                } else if (i == 6 && value != 0) {
                    words_string += "Hundred ";
                }
            }
            words_string = words_string.split("  ").join(" ");
        }
        return words_string + 'Rupees';
    }
    splitDepot(str){
        if (str) {
          str = str.split(' ');
          if (str[str.length - 1]) {
            str = str[str.length - 1];
          }
        }
        return str
      }

      formatDepotName(str){
        const depotName = str.replace('Excise', '').split('-')
        return depotName;
      }
    getSizeCode(str): any {
        return str.slice(-2);
    }
    getDateOnly(date): any {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }
    getDateByMonthFormat(date): any {
        return this.datePipe.transform(date, 'dd-MMM-yyyy');
    }
    getDateTime(date): any {
        return this.datePipe.transform(date, 'yyyy-MM-dd hh:mm:ss');
    }
    getDateTimeFormat(date): any {
        return this.datePipe.transform(date, 'dd-MM-yyyy hh:mm:ss');
    }
    getMonth(date): any {
        return this.datePipe.transform(date, 'MMM-yy');
    }
    getDateWithStartTime(date): any {
        return new Date(new Date(date).setHours(0, 0, 0, 0)).toISOString();
    }
    getDateWithEndTime(date): any {
        return new Date(new Date(date).setHours(23, 59, 59, 999)).toISOString();
    }

    convertTZ(date): any {
        const d = new Date(date);
        d.setDate(d.getDate() + 1);
        return d.toISOString();
    }

    getProductType(productCode): any {
        const productDetails: any = this.productCodeToDetails(productCode);
        return productDetails.liquorType === 'B' ? 'Beer' : productDetails.liquorType === 'M' ? 'RTD' : 'IML';
    }
    getSegment(productCode):any {
        const productDetails: any = this.productCodeToDetails(productCode);
        // return productDetails.liquorType === 'B' ? 'Beer' : 'IML';
        return liquorTypes[productDetails.liquorType];
    }
    getSegmentByType(liquorType):any {
        return liquorTypes[liquorType];
    }
    getImlBeerDatas(datas, invoiceValueCalculation = false): any {
        const productDetails: any = this.productCodeToDetails(datas.productCode);
        const liquorType: any = productDetails.liquorType;
        const unitPerCase: any = productDetails.unitsPerCase;
        const data: any = {};
        data.beerCases = liquorType === 'B' && datas.approvedCases ? datas.approvedCases : 0;
        data.beerBottles = liquorType === 'B' && datas.approvedBottles ? datas.approvedBottles : 0;
        data.beerBottleQuantity = liquorType === 'B' && datas.approvedCases ? (datas.approvedCases * unitPerCase) : 0;
        data.imlCases = liquorType !== 'B' && datas.approvedCases ? datas.approvedCases : 0;
        data.imlBottles = liquorType !== 'B' && datas.approvedBottles ? datas.approvedBottles : 0;
        data.imlBottleQuantity = liquorType !== 'B' && datas.approvedCases ? (datas.approvedCases * unitPerCase) : 0;
        data.ratePerCase = datas.mrp ? (datas.mrp * unitPerCase) : 0;
        if (datas.value && invoiceValueCalculation === false) {
            data.invoiceValue = parseFloat(datas.value);
        } else {
            data.invoiceValue = data.ratePerCase ? (parseFloat(data.ratePerCase) * (datas.approvedCases ? datas.approvedCases : 0)) : 0;
        }
        data.specialMargins = datas.specialMargin ? parseFloat(datas.specialMargin) : 0;
        data.netInvoiceValue = data.invoiceValue + data.specialMargins;
        return data;
    }

    getSizeInMl(sizeCode){
       return BottleSizes[sizeCode];
    }
    getSizeInMlByProduct(productCode) {
        const sizeCode = this.productCodeToDetails(productCode).size;
        return BottleSizes[sizeCode];
    }
    onlyAlphaNumeric(event) {
        const value = String.fromCharCode(event.keyCode);
        if (/[a-zA-Z0-9]/.test(value)) {
          return true;
        } else {
          event.preventDefault();
          return false;
        }
}

onlyNumeric(event) {   
 const charCode = (event.which) ? event.which : event.keyCode
    if (charCode != 45  && charCode > 31 && (charCode < 48 || charCode > 57)){
        return false;
    }else{
        return true;
    }
      
}
    getDuplicates<T>(input: T[]): Map<T, number[]> {
        return input.reduce((output, element, idx) => {
            const recordedDuplicates = output.get(element);
            if (recordedDuplicates) {
                output.set(element, [...recordedDuplicates, idx]);
            } else if (input.lastIndexOf(element) !== idx) {
                output.set(element, [idx]);
            }

            return output;
        }, new Map<T, number[]>());
    }
    getBottleValue(data, key): any {
        const mrp: any = data.mrp ? parseFloat(data.mrp) : 0;
        const unitPerCase: any = data.unitPerCase ? parseFloat(data.unitPerCase) : 0;
        const bottleCount: any = data[key] ? parseInt(data[key], 10) : 0;
        return (parseFloat(mrp) / unitPerCase) * bottleCount;
    }
    getValueByCasesAndBottles(data, cases, bottles): any {
        const unitPerCase = this.productCodeToDetails(data.productCode).unitsPerCase;
        const casesInToBottles: any = (data[cases] ? parseInt(data[cases], 10): 0) * unitPerCase;
        const totalBottles: any = casesInToBottles + (data[bottles] ? parseInt(data[bottles]) : 0);
        return totalBottles * (data.mrp ? parseFloat(data.mrp) : 0);
    }
    getOpeningClosingQty(data): any {
        const unitPerCase = this.productCodeToDetails(data.productCode).unitsPerCase;
        const openingBalCases = data.cases_opining ? data.cases_opining : 0;
        const openingBalBottles = data.bottles_opining ? data.bottles_opining : 0;
        const receiptsCases = data.cases_recipts ? data.cases_recipts : 0
        const receiptsBottles = data.bottles_recipts ? data.bottles_recipts : 0
        const closingBalCases = data.cases_closing ? data.cases_closing : 0;
        const closingBalBottles = data.bottles_closing ? data.bottles_closing : 0;
        data.openingBalQty = (openingBalCases * unitPerCase) + openingBalBottles;
        data.receiptsQty = (receiptsCases * unitPerCase) + receiptsBottles;
        data.closingBalQty = (closingBalCases * unitPerCase) + closingBalBottles;
    }
    getSalesBottlesQty(data): any {
        const prodData = this.getTotalScannedCasesBottles(data);
        data.shippedQty = (data.approvedCases ? data.approvedCases : 0) * prodData.unitsPerCase;
        data.breakageQty = (prodData.breakageCases * prodData.unitsPerCase) + prodData.breakageBottles;
        data.shortageQty = (prodData.shortageCases * prodData.unitsPerCase) + prodData.shortageBottles;
        data.soldQty = (prodData.totalScannedCases * prodData.unitsPerCase) + prodData.totalScannedBottles;
        const mrp = data.mrp ? data.mrp : data.retailPricings ? data.retailPricings : 0;
        data.shippedValue = data.shippedQty * mrp;
        data.breakageValue = data.breakageQty * mrp;
        data.shortageValue = data.shortageQty * mrp;
        data.soldValue = data.soldQty * mrp;
    }
    getScannedBottlesAfterBreakages(product) {
        const productData: any = {};
        productData.totalScannedBottles = 0;
        productData.totalScannedCases = 0;
        productData.shortageCases = product.shortages && product.shortages.cases ? product.shortages.cases : 0;
        productData.shortageBottles = product.shortages && product.shortages.bottles ? product.shortages.bottles : 0;
        productData.breakageCases = product.breakages && product.breakages.cases ? product.breakages.cases : 0;
        productData.breakagesBottles = product.breakages && product.breakages.bottleBreakages ? product.breakages.bottles : 0;
        const productType = this.getProductType(product.productCode);
        if (productType === 'Beer') {
            const productUpdatedDatas: any = this.getTotalScannedCasesBottles(product);
            productData.totalScannedCases = productUpdatedDatas.totalScannedCases;
            productData.totalScannedBottles = productUpdatedDatas.totalScannedBottles;
        } else {
            const productUpdatedDatas: any = this.getTotalScannedCasesBottles(product);
            productData.totalScannedCases = productUpdatedDatas.totalScannedCases;
            productData.totalScannedBottles = productUpdatedDatas.totalScannedBottles;
            if (productData.shortageBottles > 0) {
                productData.totalScannedBottles = productData.totalScannedBottles + (productData.unitsPerCase - productData.shortageBottles);
            }
        }
        return productData;
    }
    getScannedBottlesQtyWithBreakages(product) {
        const productData: any = this.getScannedBottlesAfterBreakages(product);
        const unitPerCase = this.productCodeToDetails(product.productCode).unitsPerCase;
        productData.receivedCases = productData.totalScannedCases;
        productData.receivedPartBottles = productData.totalScannedBottles;
        productData.breakagesBottles = productData.breakagesBottles;
        productData.totalReceivedBottles = (productData.receivedCases * unitPerCase) + productData.receivedPartBottles; 
        productData.totalShippedBottles = productData.totalReceivedBottles - productData.breakagesBottles;
        productData.totalShortageBottles = 0;
        if (productData.shortageCases || productData.shortageBottles) {
            productData.totalShortageBottles = (productData.shortageCases * unitPerCase) + productData.shortageBottles
        }
        return productData;
    }
    getScannedBottlesImlBeerAfterBreakages(datas) {
        const productDetails: any = this.productCodeToDetails(datas.productCode);
        const liquorType: any = productDetails.liquorType;
        const data: any = {};
        const productData: any = this.getScannedBottlesAfterBreakages(datas);
        data.totalScannedCases = productData.totalScannedCases ? productData.totalScannedCases : 0;
        data.totalScannedBottles = productData.totalScannedBottles ? productData.totalScannedBottles : 0;
        data.breakageBottles = datas.breakages && datas.breakages.bottles ? datas.breakages.bottles : 0;
        data.beerTotalScannedCases = liquorType === 'B' && productData.totalScannedCases ? productData.totalScannedCases : 0;
        data.beerTotalScannedBottles = liquorType === 'B' && productData.totalScannedBottles ?productData.totalScannedBottles : 0;
        data.imlTotalScannedCases = liquorType !== 'B' && productData.totalScannedCases ? productData.totalScannedCases : 0;
        data.imlTotalScannedBottles = liquorType !== 'B' && productData.totalScannedBottles ? productData.totalScannedBottles : 0;
        data.invoiceValue = datas.value ? parseFloat(datas.value) : 0;
        data.breakageCases = productData.breakageCases ? productData.breakageCases : 0; 
        data.shortageCases = productData.shortageCases;
        data.shortageBottles = productData.shortageBottles;
        data.liquorType = liquorType;
        data.unitsPerCase = productDetails.unitsPerCase;
        data.mrp = datas.mrp ? datas.mrp : 0;
        data.approvedQty = datas.approvedQty ? datas.approvedQty : (data?.approvedCases ? data?.approvedCases : 0);
        return data;
    }
    getImlBeerAfterBreakagesConvBottlesToCases(datas) {
        const productData: any = this.getScannedBottlesImlBeerAfterBreakages(datas);
        if (productData.totalScannedBottles > productData.unitsPerCase) {
            const totalScannedCases = Math.floor(productData.totalScannedBottles / productData.unitsPerCase);
            productData.totalScannedBottles = productData.totalScannedBottles - (totalScannedCases * productData.unitsPerCase);
            productData.totalScannedCases = productData.totalScannedCases + totalScannedCases; 
            productData.beerTotalScannedCases = productData.liquorType === 'B' && productData.totalScannedCases ? productData.totalScannedCases : 0;
            productData.beerTotalScannedBottles = productData.liquorType === 'B' && productData.totalScannedBottles ? productData.totalScannedBottles : 0;
            productData.imlTotalScannedBottles = productData.liquorType !== 'B' && productData.totalScannedBottles ? productData.totalScannedBottles : 0;
            productData.imlTotalScannedCases = productData.liquorType !== 'B' && productData.totalScannedCases ? productData.totalScannedCases : 0;
        }
        productData.value = productData.mrp ? productData.mrp * ((productData.totalScannedCases * productData.unitsPerCase) + productData.totalScannedBottles) : 0;
        productData.totalBottles = (productData.totalScannedCases * productData.unitsPerCase) + productData.totalScannedBottles;
        //qty
        productData.totalShippedBottles = productData.approvedQty * productData.unitsPerCase;
        productData.breakageBottleQty = productData.breakageBottles + (productData.breakageCases ? productData.breakageCases * productData.unitsPerCase : 0);
        productData.breakageValue = productData.breakageBottleQty * datas.mrp;
        return productData;
    }
    convertCasesIntoBottles(productCode, cases, bottles): any {
        const unitsPerCase: any = this.productCodeToDetails(productCode).unitsPerCase;
        const productData: any = {};
        if (bottles >= unitsPerCase) {
            const totalScannedCases = Math.floor(bottles / unitsPerCase);
            productData.bottles = bottles - (totalScannedCases * unitsPerCase);
            productData.cases = cases + totalScannedCases; 
        } else {
            productData.bottles = bottles;
            productData.cases = cases;
        }
        return productData;
    }
    getBarValue(datas): any {
        const productDatas: any = {};
        const unitsPerCase: any = this.productCodeToDetails(datas.productCode).unitsPerCase;
        const cases = datas.totalScannedCases ? datas.totalScannedCases : (datas.casesCount ? datas.casesCount : 0);
        const totalAr = datas['Additional Retail Excise Tax (ARET 1)'] + datas[
				'Additional Retail Excise Tax (ARET 2)'] + datas[
							'Rationalization Of Rates 2'] + datas[
							'Rationalization Of Rates 1'] + (
							(datas["ISSUE_PRICE_ROUNDED"] * 0.1) / unitsPerCase);
        const issuePrice = Math.round(cases * datas["ISSUE_PRICE_ROUNDED"]);
        const tat = (cases * (Math.ceil(totalAr) * unitsPerCase));
        const totalSplMargin = Math.round(cases * (datas['ADD.PRIV.FEE PER CASE']));
        const totalTcs = (issuePrice + totalSplMargin + tat) * 0.01;
        const tcs = Math.ceil(totalTcs);
        productDatas['issue_price'] = issuePrice;
        productDatas['basicPrice'] = datas.basicPricing;
        productDatas['total_spl_margin'] = totalSplMargin;
        productDatas['tcs'] = tcs;
        productDatas['totalAret'] = totalAr;
        productDatas['mrp'] = datas['Final Mrp'];
        productDatas['sale_indent_value'] = (issuePrice + totalSplMargin + tat);
        return productDatas;
    }
    getBarSaleValue(cons: any = [], productCode: any = ''): any {
        const saleValueDatas = cons.filter(el => el.productCode === productCode);
        return saleValueDatas && saleValueDatas.length ? saleValueDatas[0].saleValue : 0;
    }
    getMinMax(datas: any = [], key: any): any {
        let min;
        let max;
        min = new Date(Math.min(...datas.map(element => new Date(element[key]))));
        max = new Date(Math.max(...datas.map(element => new Date(element[key]))));
        min = this.getDateOnly(min);
        max = this.getDateOnly(max);
        return {min: min, max: max};
    }
    getReceivedDetails(item): any {
        if (!item.unitsPerCase) {
            item.unitsPerCase = this.productCodeToDetails(item.productCode).unitsPerCase;
        }
        //shipped details
        item.shippedCases = item.shipmentQty ? item.shipmentQty : (item.approvedCases ? item.approvedCases : 0);
        item.shippedBottles = item.approvedBottles ? item.approvedBottles : 0;
        item.shippedBottlesQty = (item.shippedCases * item.unitsPerCase) + item.shippedBottles;
        
        //breakage details
        item.breakageCases = item.breakages && item.breakages.cases ? item.breakages.cases : 0;
        item.breakageBottles = item.breakages && item.breakages.bottles ? item.breakages.bottles : 0;
        item.breakageBottlesQty = (item.breakageCases * item.unitsPerCase) + item.breakageBottles;
        
        //shortage details
        item.shortageCases = item.shortages && item.shortages.cases ? item.shortages.cases : 0
        item.shortageBottles = item.shortages && item.shortages.bottles ? item.shortages.bottles : 0;
        item.shortageBottlesQty = (item.shortageCases * item.unitsPerCase) + item.shortageBottles;
        
        //breakage shortage details
        item.breakageShortageBottlesQty = item.breakageBottlesQty + item.shortageBottlesQty;
        
        //scanned details
        // item.totalScannedCases = Math.floor((item.shippedBottlesQty - item.breakageShortageBottlesQty) / item.unitsPerCase);
        item.totalScannedCases = item.casesCount ? item.casesCount : 0;
        item.totalReceivedBottles = item.bottlesCount ? item.bottlesCount : 0;
        item.totalReceivedBottlesQty = (item.totalScannedCases * item.unitsPerCase) + item.totalReceivedBottles;
        item.totalScannedBottles = item.shippedBottlesQty - item.totalReceivedBottlesQty - item.breakageShortageBottlesQty;
        item.totalScannedBottlesQty = (item.totalScannedCases * item.unitsPerCase) + item.totalScannedBottles;

        if (this.getProductType(item.productCode) === 'Beer') {
            item.beerTotalScannedCases = item.totalScannedCases;
            item.beerTotalScannedBottles = item.totalScannedBottles;
        } else {
            //if repack
            if (item.breakages && item.breakages.bottleBreakages && item.breakages.bottleBreakages.length === 0) {
                item.totalScannedCases = item.shippedCases;
                item.totalScannedBottles = 0;
                item.breakageBottles = 0;
                item.breakageBottlesQty = 0;
                item.shortageBottles = 0;
                item.shortageBottlesQty = 0;
            }
            item.imlTotalScannedCases = item.totalScannedCases;
            item.imlTotalScannedBottles = item.totalScannedBottles;
        }
        item.breakageValue = item.breakageBottlesQty * (item.mrp ? item.mrp : 0);
        item.shortageValue = item.shortageBottlesQty * (item.mrp ? item.mrp : 0);
        item.soldValue = item.totalScannedBottlesQty * (item.mrp ? item.mrp : 0);
    }
    getTotalScannedCasesBottlesNew(item): any {
        if (!item.unitsPerCase) {
            item.unitsPerCase = this.productCodeToDetails(item.productCode).unitsPerCase;
        }        
        item.totalScannedCases = item.casesCount ? item.casesCount : 0;
        item.totalScannedBottles = 0;
        item.imlTotalScannedCases = 0;
        item.beerTotalScannedCases = 0;
        item.breakageCases = item.breakages && item.breakages.cases ? item.breakages.cases : 0;
        item.breakageBottles = item.breakages && item.breakages.bottles ? item.breakages.bottles : 0;
        item.shortageCases = item.shortages && item.shortages.cases ? item.shortages.cases : 0
        item.shortageBottles = item.shortages && item.shortages.bottles ? item.shortages.bottles : 0;
        const shippedBottlesQty = ((item.shipmentQty ? item.shipmentQty : (item.approvedCases ? item.approvedCases : 0)) * item.unitsPerCase) + (item.approvedBottles ? item.approvedBottles : 0);
        let totalScannedBottles = (shippedBottlesQty - (item.totalScannedCases * item.unitsPerCase) + (item.bottlesCount ? item.bottlesCount : 0)) - ((item.breakageCases * item.unitsPerCase) + item.breakageBottles + (item.shortageCases * item.unitsPerCase) + item.shortageBottles);
        item.totalScannedCases = item.casesCount + Math.floor(totalScannedBottles/item.unitsPerCase);
        item.totalScannedBottles = totalScannedBottles % item.unitsPerCase;
        item.breakageBottleQty = (item.breakageCases * item.unitsPerCase) + item.breakageBottles;
        item.breakageValue = item.breakageBottleQty * (item.mrp ? item.mrp : 0);
        if (this.getProductType(item.productCode) === 'Beer') {
            item.beerTotalScannedCases = item.totalScannedCases;
            item.beerTotalScannedBottles = item.totalScannedBottles;
        } else {
            item.imlTotalScannedCases = item.totalScannedCases;
            item.imlTotalScannedBottles = item.totalScannedBottles;
        }
        return item;
    }
    getTotalScannedCasesBottles(item): any {
        item.totalScannedCases = item.casesCount ? item.casesCount : 0;
        item.totalScannedBottles = 0;
        item.imlTotalScannedCases = 0;
        item.beerTotalScannedCases = 0;
        const unitsPerCase = this.productCodeToDetails(item.productCode).unitsPerCase;
        item.unitsPerCase = unitsPerCase;
        item.breakageBottles = item.breakages && item.breakages.bottles ? item.breakages.bottles : 0;
        item.breakageCases = item.breakages && item.breakages.cases ? item.breakages.cases : 0;
        item.shortageCases = item.shortages && item.shortages.cases ? item.shortages.cases : 0
        item.shortageBottles = item.shortages && item.shortages.bottles ? item.shortages.bottles : 0;
        if (this.getProductType(item.productCode) === 'Beer') {
            if (item.breakageBottles > 0 || item.shortageBottles > 0) {
                let totalScannedBottles = (((item.shipmentQty ? item.shipmentQty : (item.approvedCases ? item.approvedCases : 0)) * item.unitsPerCase) - (item.totalScannedCases * item.unitsPerCase)) - ((item.breakageCases * item.unitsPerCase) + item.breakageBottles + (item.shortageCases * item.unitsPerCase) + item.shortageBottles);
                item.totalScannedCases = item.casesCount + Math.floor(totalScannedBottles/unitsPerCase);
                item.totalScannedBottles = totalScannedBottles % unitsPerCase;
            }
            if (item.bottlesCount) {
                item.totalScannedBottles = item.totalScannedBottles + item.bottlesCount;            
            }
            item.beerTotalScannedCases = item.totalScannedCases;
            item.beerTotalScannedBottles = item.totalScannedBottles;
            return item;
        }
        if(item.breakages){
            if(item.breakages.bottleBreakages){
              let bottleBreakagesLength = item.breakages.bottleBreakages.length; 
              if (item.breakages.bottleBreakages.length > 1) {
                const caseCodes: any = item.breakages.bottleBreakages.map(el => el.caseCode);
                const isDuplicate: any = caseCodes.filter((c, idx) => caseCodes.indexOf(c) != idx);
                if (isDuplicate.length) {
                  bottleBreakagesLength = bottleBreakagesLength - isDuplicate.length;
                }
              }
            //   item.totalScannedBottles = (bottleBreakagesLength * unitsPerCase) - item.breakages.bottles;
                if (bottleBreakagesLength === 1 && item.breakages.bottleBreakages[0].bottlesCount) {
                    item.totalScannedBottles = (item.approvedCases * unitsPerCase) - (item.casesCount * unitsPerCase) - item.breakages.bottles - item.shortageBottles;
                } else {
                    let totalScannedBottles = (bottleBreakagesLength * unitsPerCase) - item.breakages.bottles - item.shortageBottles;
                    item.totalScannedCases = item.casesCount + Math.floor(totalScannedBottles/unitsPerCase);
                    item.totalScannedBottles = totalScannedBottles % unitsPerCase;
                    if (item.breakages.bottleBreakages && item.breakages.bottleBreakages.length === 0) {
                    item.totalScannedCases = item.casesCount
                    }
                }
            }
        }
        if(item.shortages) {
            let totalScannedBottles = (((item.shipmentQty ? item.shipmentQty : (item.approvedCases ? item.approvedCases : 0)) * unitsPerCase) - (item.casesCount * unitsPerCase)) - ((item.breakageCases * unitsPerCase) + item.breakageBottles + (item.shortageCases * unitsPerCase) + item.shortageBottles);
            item.totalScannedCases = item.casesCount + Math.floor(totalScannedBottles/unitsPerCase);
            item.totalScannedBottles = totalScannedBottles % unitsPerCase;
        }
        item.imlTotalScannedCases = item.totalScannedCases;
        // if (item.breakages && item.breakages.bottleBreakages && item.breakages.bottleBreakages.length === 0) {
        //     item.breakageBottles = 0;
        //     item.totalScannedBottles = 0;
        // }
        if (item.bottlesCount) {
            item.totalScannedBottles = item.totalScannedBottles + item.bottlesCount;            
        }
        item.breakageBottleQty = item.breakageBottles + (item.breakageCases * item.unitsPerCase);
        item.breakageValue = item.breakageBottleQty * (item.mrp ? item.mrp : 0);
        return item;
    }
    getUserName(): any {
        let userName: any = '';
        const lappCurrentUserModules: any = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
        if (lappCurrentUserModules) {
            userName = lappCurrentUserModules.activeUsersName;
        }
        return userName;
    }

    convertToIST(date:any){
       return this.datePipe.transform(date,'medium', '+0530')
    }
    getDatesBetween(startDate, endDate){
        let dates = [];
        startDate = new Date(startDate);
        endDate = new Date(endDate);
        let currentDate = new Date(startDate);
        while (currentDate <= endDate) {
          dates.push(new Date(currentDate));
          currentDate.setDate(currentDate.getDate() + 1);
        }
        return dates;
      };

    allowOnlyPositiveNumbers(event){
        if (event.key === 'ArrowUp') {
            event.preventDefault();
        } else if (event.key === 'ArrowDown') {
            event.preventDefault();
        }
        if(!((event.keyCode > 95 && event.keyCode < 106)
        || (event.keyCode > 47 && event.keyCode < 58) 
        || event.keyCode == 8)) {
            return false;
        }
    }

allowNumbersAndDecimals(evt:any,value){
    const price = String(value)
    var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode == 46) {
        if (price && price.indexOf('.') === -1) {
          return true;
        } else {
          return false;
        }
      } else {
        if (charCode > 31 &&
          (charCode < 48 || charCode > 57))
          return false;
      }
      return true;
}
getValidityDate() {
    //It'll return last date of the current month
    const currentDate = new Date();
    const currentMonthLastdate =  new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const currentMonthLastDateTime = new Date(currentMonthLastdate);
    currentMonthLastDateTime.setHours(23, 59, 59);
    return currentMonthLastDateTime;
  }
  getFutureDateByDayCount(currentDate, days): any {
    const date = new Date(currentDate);
    date.setDate(date.getDate() + days);
    return date;
  }
  getPastDateByDayCount(currentDate, days): any {
    const date = new Date(currentDate);
    date.setDate(date.getDate() - days);
    return date;
  }

  convertStringToFloat(value:any){
    return value ? parseFloat(value) : 0
  }
  convertProductCodeLength11TO12(productCode): any {
    const prefix = productCode.slice(0, 5);
    const suffix = productCode.slice(5, 11);
    return productCode = prefix + "0" + suffix;
  }
  getFirstAndLastDateOfMonth(date) {
    const newDate = new Date(date);
    const y = newDate.getFullYear();
    const m = newDate.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);
    return {fromDate: this.getDate(firstDay), toDate: this.getDate(lastDay)};
  }
  getDate(date): any {
    return date.getDate();
  }
  getMonthNameAndYear(date): any {
    return this.datePipe.transform(date, 'MMMM-yyyy');
  }
  getFullYear(date): any {
    return this.datePipe.transform(date, 'yyyy');
  }

}
