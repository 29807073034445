import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormArray } from '@angular/forms';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { ReplaySubject } from 'rxjs';
import { CommonsService } from 'src/app/core/services/commons.service';
import { ExciseLicenseeService } from 'src/app/core/services/excise-licensee.service';
import { UtilService } from 'src/app/core/utils/utility-helper';

@Component({
  selector: 'app-excise-licensee-dialog',
  templateUrl: './excise-licensee-dialog.component.html',
  styleUrls: ['./excise-licensee-dialog.component.scss']
})
export class ExciseLicenseeDialogComponent implements OnInit {
  ExcLicDialogForm: FormGroup;
  supervisorAddress: FormArray;
  address: FormArray;
  bankDetails: FormArray;
  error: string;
  page: number;
  limit: number;
  depots = [];
  revenues = [];
  exciseDistricts = [];
  saveDisabled: boolean;
  offset: number;
  public depotFilterCtrl: FormControl = new FormControl();
  public revenueFilterCtrl: FormControl = new FormControl();
  shopDetails: any;
  filteredDepots: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  filteredRevenues: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  exciseCircles: any = [];
  defRetCode : any;
  today=new Date();

  constructor(
    private fb: FormBuilder, private dialogRef: MatDialogRef < ExciseLicenseeDialogComponent > ,
    @Inject(MAT_DIALOG_DATA) public data, private commonsService: CommonsService,
    public util: UtilService, private ExciseLicenseeService: ExciseLicenseeService,
    private messageService:MessageService,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
  console.log(this.data)
    this.getDepots();
    if(this.data.featureName !== "Shops" && !this.data.colName){
      this.getRetailerCode();
    }
    this.getCommonList();
    this.ExcLicDialogForm = this.fb.group({
      retailerCode: ['', [Validators.required, ]],
      retailerId: [''],
      name: ['', [Validators.required, ]],
      address: this.fb.array([this.updateAddressInformation()]),
      licenseInfo: [''],
      licenseName: [''],
      licenseNumber: [''],
      licenseAmount: [''],
      assignedDepot: ['', [Validators.required, ]],
      aadharNo: [''],
      panNo: [''],
      bankDetails: this.fb.array([this.updateBankInformation()]),      
      operationStartDate: [this.today],
      gslNo: ['', [Validators.required, ]],
      status: ['Active', [Validators.required, ]],
      // locationType: [''],
      supervisorName: ['', [Validators.required, ]],
      supervisorAddress: this.fb.array([this.updateSupervisorAddressInformation()]),
      // powerCutDuration: [''],
    });
    // this.ExcLicDialogForm.controls.operationStartDate.setValue(this.today);
    this.ExcLicDialogForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    });
    if(this.data && this.data.colName){
      this.patchData()
    }
    this.util.matSelectFilter(this, "depotFilterCtrl", "filteredDepots", "depots");
    this.util.matSelectFilter(this, "revenueFilterCtrl", "filteredRevenues", "revenues");
  }

  get f() {
    return this.ExcLicDialogForm.controls;
  }
  patchData(){
    this.defRetCode=this.util.formatStringByParamAndIndex(this.data.name, '-', 0),
    console.log(this.util.formatStringByParamAndIndex(this.data.name, '-', 0),this.util.formatStringByParamAndIndex(this.data.name, '-', 1))
    this.ExcLicDialogForm.patchValue({
      
      name:this.util.formatStringByParamAndIndex(this.data.name, '-', 1),
      assignedDepot:this.data.assignedDepot,
      licenseInfo:this.data.licenseInfo,
      licenseName:this.data.licenseName,
      licenseNumber:this.data.licenseNumber,
      licenseAmount:this.data.licenseAmount,
      operationStartDate:this.data.operationStartDate,
      gslNo:this.data.gslNo,
      aadharNo:this.data.aadharNo,
      panNo:this.data.panNo,
      // address: this.fb.array([this.updateAddressInformation()]),
      supervisorName: this.data.supervisorName,
      // supervisorAddress: this.fb.array([this.updateSupervisorAddressInformation()]),
    })
    this.updateSupervisorAddressInformation();
    this.updateAddressInformation()

  }
  updateAddressInformation(){
    if(this.data && this.data.colName){
      return this.fb.group({
        address: [this.data.address.address],
        divisonName: [this.data.address.divisonName],
        excise_circle: [this.data.address.excise_circle],
        revenue_district: [this.data.address.revenue_district],
        zone: [this.data.address.zone],
        state: [this.data.address.state],
        pincode: [this.data.address.pincode],
        emailIds: [this.data.address.emailIds[0].mailId],
        phoneNumbers: [this.data.address.phoneNumbers[0].number],
      });
    }else{
      return this.fb.group({
        address: ['', [Validators.required, ]],
        divisonName: ['', [Validators.required, ]],
        excise_circle: ['', [Validators.required, ]],
        revenue_district: ['', [Validators.required, ]],
        zone: [''],
        state: [''],
        pincode: ['', [Validators.minLength(6), Validators.maxLength(6)]],
        emailIds: ['', [Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
        phoneNumbers: ['', [Validators.minLength(10), Validators.maxLength(14)]],
      });
    }

  }
  updateSupervisorAddressInformation(){
    if(this.data  && this.data.colName){
      return this.fb.group({
        mandal: [this.data.supervisorAddress[0].mandal],
        village: [this.data.supervisorAddress[0].village],
        residenceDistance: [this.data.supervisorAddress[0].residenceDistance],
        travelTime: [this.data.supervisorAddress[0].travelTime],
      });
    }else{
      return this.fb.group({
        mandal: [''],
        village: [''],
        residenceDistance: [''],
        travelTime: [''],
      });
    }
  }
  updateBankInformation(){
    return this.fb.group({
 
      bankName: [''],
      bank_number: [''],
      ifsc_code: [''],
      branch: [''],
      account_name: [''],
      village: [''],
      mandal: [''],
      district: [''],
      distance: [''],
      travelTime: [''],
    });
  }

  getCommonList() {
    this.spinner.show();
    const req_Data = {
      action: 'common_apis',
      q: `{"data": {"action": "get_static_codes", "value": ["revenueDistricts", "exciseDistricts"]}}`
    }
    this.commonsService.getCommonEntites(req_Data).subscribe(result => {
      this.revenues = result['revenueDistricts'];
      this.filteredRevenues.next(this.revenues);
      this.exciseDistricts = result.exciseDistricts;
    })
    this.spinner.hide();
  }

  getDepots() {
    this.spinner.show();
    let paramObj = {
      action: 'common_apis',
      q: '{"data": ["get_depots"]}'
    }
    this.commonsService.getCommonEntites(paramObj).subscribe((res) => {
      this.depots = res['get_depots'].map((depot) => depot.depotCode);
      this.filteredDepots.next(this.depots);
    });
    this.spinner.hide();
  }
  
  getRetailerCode(){
    this.spinner.show();
    let qData = {
      "data": ["get_vendor_code"]
    }
    const req_Data = {
      "action": "common_apis",
      "q": JSON.stringify(qData)
    };
    
    this.commonsService.getCommonEntites(req_Data).subscribe((res) => {
      this.defRetCode = res['get_vendor_code']

      // this.filteredProducts.next(this.brandList);
    });
    this.spinner.hide();
  }

  changeRevenueDistrict(value): any {
    this.exciseCircles = [];
    this.ExcLicDialogForm.get('address')['controls'][0].controls.excise_circle.setValue('');
    const excCircle = this.exciseDistricts.filter(el => el.distName === value);
    if (excCircle && excCircle.length && excCircle[0].exciseCircles) {
      this.exciseCircles = excCircle[0].exciseCircles;
    }
  }

  createRetailer() {
    let featureName:any='';
    console.log(this.ExcLicDialogForm,'ExcLicDialogForm');
    let createdData : any = this.ExcLicDialogForm.value ;
    switch (this.data.featureName) {
      case 'Shops':
        featureName= 'add_shops'    
        console.log("shops.");
        break;
      case 'Bars':
        featureName= 'add_bars'
        console.log("Bars.");
        break;
      case '2T':
        featureName= 'add_vendor2t'
        console.log("2T.");
        break;
      case '2F':
        featureName= 'add_vendor2f'
        console.log("2F.");
        break;
      case 'Clubs':
        featureName= 'add_clubs'
        console.log("Clubs.");
        break;
      case 'TD1':
        featureName= 'add_td1'
        console.log("TD1.");
        break;
      case 'TD2':
        featureName= 'add_td2'
        console.log("TD2.");
        break;
      case 'EP':
        featureName= 'add_ep'
        break;
      case '2A':
        featureName= 'add_2a'
        break
      // case 'EO':
      //   featureName= 'add_eo'
      //   console.log("EO.");
      //   break;
      // case 'Suspended Retailer':
      //   featureName= 'add_suspendedRetailer'
      //   console.log("Suspended Retailer.");
      //   break;
  }
    const payload: any = {
      action: featureName,
      // data: {}
    };
    console.log(createdData,'ExcLicDialogForm1');
    console.log(payload,'payload');
    this.spinner.show();
    if(this.data.featureName != 'Shops'){
      payload.data = {}
      payload.data.assignedDepot = createdData.assignedDepot;
      payload.data.name = createdData.retailerCode + '-' + createdData.name;
      payload.data.vendorId = createdData.retailerCode + '-' + createdData.name;
      payload.data.address = createdData.address[0].address;
      payload.data.divisonName = createdData.address[0].divisonName;
      payload.data.excise_circle = createdData.address[0].excise_circle;
      payload.data.revenue_district = createdData.address[0].revenue_district;
      payload.data.zone = createdData.address[0].zone;
      payload.data.state = createdData.address[0].state;
      payload.data.pincode = createdData.address[0].pincode;
      payload.data.emailIds = createdData.address[0].emailIds;
      payload.data.phoneNumbers = createdData.address[0].phoneNumbers;
      payload.data.licenseInfo = createdData.licenseInfo;
      payload.data.licenseName = createdData.licenseName;
      payload.data.licenseNumber = createdData.licenseNumber;
      payload.data.licenseAmount = createdData.licenseAmount;
      payload.data.aadharNo = createdData.aadharNo;
      payload.data.bankName = createdData.bankDetails[0].bankName;
      payload.data.bank_number = createdData.bankDetails[0].bank_number;
      payload.data.ifsc_code = createdData.bankDetails[0].ifsc_code;
      payload.data.branch = createdData.bankDetails[0].branch;
      payload.data.account_name = createdData.bankDetails[0].account_name;
      payload.data.mandal = createdData.bankDetails[0].mandal;
      payload.data.district = createdData.bankDetails[0].district;
      payload.data.distance = createdData.bankDetails[0].distance;
      payload.data.travelTime = createdData.supervisorAddress[0].travelTime;
      payload.data.operationStartDate = createdData.operationStartDate;
      payload.data.gslNo = createdData.gslNo;
      payload.data.panNo = createdData.panNo;
      // payload.data.locationType = createdData.locationType;
      payload.data.supervisorName = createdData.supervisorName;
      payload.data.mandal = createdData.supervisorAddress[0].mandal;
      payload.data.village = createdData.supervisorAddress[0].village;
      payload.data.residenceDistance = createdData.supervisorAddress[0].residenceDistance;
      payload.data.travelTime = createdData.supervisorAddress[0].travelTime;
      // payload.data.powerCutDuration = createdData.powerCutDuration;
    }else{
      payload.data = createdData
      if (payload.data.retailerCode && payload.data.retailerCode.length === 4) {
        payload.data.retailerCode = '0' + payload.data.retailerCode;
      }
      payload.data.retailerId = payload.data.retailerCode + '-' + payload.data.name;
      delete payload.data.retailerCode;
      payload.data.name = payload.data.retailerId;
      payload.data.address = payload.data.address[0];
      const emailIds = payload.data.address.emailIds; 
      payload.data.address.emailIds = [];
      payload.data.address.emailIds[0] = {mailId: emailIds, type: '', isVerified: 'True'};
      const phoneNumbers = payload.data.address.phoneNumbers; 
      payload.data.address.phoneNumbers = [];
      payload.data.address.phoneNumbers[0] = {number: phoneNumbers, type: '', isVerified: 'True'};
    }

    console.log(createdData,'ExcLicDialogForm2');
    console.log(payload,'payload');
    
    this.ExciseLicenseeService.createLicensee(payload).subscribe((res: any) => {
      if(res.status === 'SUCCESS'){
        this.dialogRef.close('Created Retailer Successfully');
      }else{
        this.messageService.add({ severity: 'error', summary: 'Error', detail: "Cretion faild" });
      }
      this.spinner.hide();
    }, (err) => {
      this.error = err.error.message;
      this.spinner.hide();
    });
  }

  dialogClose(): void{
    // this.dialogRef.close();
  }
  updateRetailer(){
    let featureName:any='';
    console.log(this.ExcLicDialogForm,'ExcLicDialogForm');
    let createdData : any = this.ExcLicDialogForm.value ;
    const payload: any = {
      action: 'update_vendor',
      // data: {}
    };
    this.spinner.show();
    if(this.data.featureName != 'Shops'){
      payload.data = {}
      payload.data.assignedDepot = createdData.assignedDepot;
      payload.data.name = createdData.retailerCode + '-' + createdData.name;
      payload.data.vendorId = createdData.retailerCode + '-' + createdData.name;
      payload.data.address = createdData.address[0].address;
      payload.data.divisonName = createdData.address[0].divisonName;
      payload.data.excise_circle = createdData.address[0].excise_circle;
      payload.data.revenue_district = createdData.address[0].revenue_district;
      payload.data.zone = createdData.address[0].zone;
      payload.data.state = createdData.address[0].state;
      payload.data.pincode = createdData.address[0].pincode;
      payload.data.emailIds = createdData.address[0].emailIds;
      payload.data.phoneNumbers = createdData.address[0].phoneNumbers;
      payload.data.licenseInfo = createdData.licenseInfo;
      payload.data.licenseName = createdData.licenseName;
      payload.data.licenseNumber = createdData.licenseNumber;
      payload.data.licenseAmount = createdData.licenseAmount;
      payload.data.aadharNo = createdData.aadharNo;
      payload.data.bankName = createdData.bankDetails[0].bankName;
      payload.data.bank_number = createdData.bankDetails[0].bank_number;
      payload.data.ifsc_code = createdData.bankDetails[0].ifsc_code;
      payload.data.branch = createdData.bankDetails[0].branch;
      payload.data.account_name = createdData.bankDetails[0].account_name;
      payload.data.mandal = createdData.bankDetails[0].mandal;
      payload.data.district = createdData.bankDetails[0].district;
      payload.data.distance = createdData.bankDetails[0].distance;
      payload.data.travelTime = createdData.supervisorAddress[0].travelTime;
      payload.data.operationStartDate = createdData.operationStartDate;
      payload.data.gslNo = createdData.gslNo;
      payload.data.panNo = createdData.panNo;
      // payload.data.locationType = createdData.locationType;
      payload.data.supervisorName = createdData.supervisorName;
      payload.data.mandal = createdData.supervisorAddress[0].mandal;
      payload.data.village = createdData.supervisorAddress[0].village;
      payload.data.residenceDistance = createdData.supervisorAddress[0].residenceDistance;
      payload.data.travelTime = createdData.supervisorAddress[0].travelTime;
      // payload.data.powerCutDuration = createdData.powerCutDuration;
    }else{
      payload.data = createdData
      if (payload.data.retailerCode && payload.data.retailerCode.length === 4) {
        payload.data.retailerCode = '0' + payload.data.retailerCode;
      }
      payload.data.retailerId = payload.data.retailerCode + '-' + payload.data.name;
      delete payload.data.retailerCode;
      payload.data.name = payload.data.retailerId;
      payload.data.address = payload.data.address[0];
      const emailIds = payload.data.address.emailIds; 
      payload.data.address.emailIds = [];
      payload.data.address.emailIds[0] = {mailId: emailIds, type: '', isVerified: 'True'};
      const phoneNumbers = payload.data.address.phoneNumbers; 
      payload.data.address.phoneNumbers = [];
      payload.data.address.phoneNumbers[0] = {number: phoneNumbers, type: '', isVerified: 'True'};
    }

    this.ExciseLicenseeService.updateLicensee(payload).subscribe((res: any) => {
      if(res.status === 'SUCCESS'){
        this.dialogRef.close('Updated Successfully');
      }else{
        this.messageService.add({ severity: 'error', summary: 'Error', detail: "Updation faild" });
      }
      this.spinner.hide();
    }, (err) => {
      this.error = err.error.message;
      this.spinner.hide();
    });
  
  
  }
}
