import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const baseUrl = environment.baseUrl;
const exciseUrl = baseUrl + 'excise_licensee';

const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
const feature = userModules && userModules.mod_feats && userModules.mod_feats['Excise Licensee'] && userModules.mod_feats['Excise Licensee'].features ? userModules.mod_feats['Excise Licensee'].features.map(feat => feat.feature_name) : [];

@Injectable({
  providedIn: 'root'
})
export class ExciseLicenseeService {

  constructor(private http: HttpClient) { }

  getShopsDetails(offset, limit, search) {
    return this.http.get('assets/samples/shops.json');
  }

  getBarsDetails(offset, limit, search){
    return this.http.get('assets/samples/bars.json');
  }

  getTD1Details(offset, limit, search){
    return this.http.get('assets/samples/td1.json');
  }

  getTD2Details(offset, limit, search){
    return this.http.get('assets/samples/td2.json');
  }

  getTwoFDetails(offset, limit, search){
    return this.http.get('assets/samples/two-f.json');
  }

  getTwoTDetails(offset, limit, search){
    return this.http.get('assets/samples/two-t.json');
  }

  getClubsDetails(offset, limit, search){
    return this.http.get('assets/samples/clubs.json');
  }

  getEODetails(offset, limit, search){
    return this.http.get('assets/samples/eo.json');
  }

  getSuspendRetailerDetails(offset, limit, search){
    return this.http.get('assets/samples/suspend-retailer.json');
  }

  getRetailerPartnerDetails(offset, limit, search){
    return this.http.get('assets/samples/retailer-partner.json');
  }

  getExciseFeatures(paramObj: any = '') {
    return this.http.get(exciseUrl, { params: paramObj });
  }

  updateForm27d(obj:any){
    return this.http.post(exciseUrl, obj);
  }

  updateLicensee(data) {
    return this.http.put(exciseUrl, data);
  }

  getProfileDetails(q,action){
    const paramObj: any = {};
    q ? (paramObj.q = q) : (q = null);
    action ? (paramObj.action = action) : (action = null);
    return this.http.get(exciseUrl, {params: paramObj});
  }

  createLicensee(payload: any) {
    return this.http.post(exciseUrl, payload);
  }

  getFeatures() {
    const headerDict = {
      feature
    };
    return this.http.get(exciseUrl, { headers: headerDict });
  }
}
